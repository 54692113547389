import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Spinner } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spinner"
    }}>{`Spinner`}</h1>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Spinner />'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Spinner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Spinner mdxType="Spinner" />
    </Playground>
    <h2 {...{
      "id": "size-and-colors"
    }}>{`Size and colors`}</h2>
    <Playground __position={1} __code={'<Spinner size=\"24px\" color=\"primary\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Spinner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Spinner size="24px" color="primary" mdxType="Spinner" />
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Spinner} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      